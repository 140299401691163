import { useEffect, useState } from "react";
import Web3 from "web3";
import "./App.css";

export default function App() {
  let [connectedAccount, setConnectedAccount] = useState(null);
  let [amount, setAmount] = useState("0.001");

  async function handleLogin() {
    const { ethereum } = window;

    if (!ethereum) return alert("Please install MetaMask");

    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setConnectedAccount(accounts[0]);
    } catch (err) {
      console.error(err);
      alert("An error occurred while trying to connect to MetaMask");
    }
  }

  async function checkWalletIsConnected() {
    const { ethereum } = window;

    if (!ethereum) return alert("Please install MetaMask");

    try {
      const accounts = await ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length) {
        setConnectedAccount(accounts[0]);
      }
    } catch (err) {
      console.error(err);
      alert("An error occurred while trying to connect to MetaMask");
    }
  }

  async function pay() {
    const { ethereum } = window;
    const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");
    const parsedAmount = parseInt(web3.utils.toWei(amount, "ether")).toString(
      16
    );

    try {
      await ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: connectedAccount,
            to: "0xc7eD70cBaDbBFe01521a131a7B9A641BDE199B2f",
            value: parsedAmount,
            data: "",
          },
        ],
      });
      alert("DONE");
    } catch (err) {
      console.error(err);
      alert("An error occurred!");
    }
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, []);

  return (
    <div className="App">
      <header>
        {connectedAccount ? (
          <div>
            <h1>Connected wallet:</h1>
            <h3>{connectedAccount}</h3>
          </div>
        ) : (
          <div>
            <h1>Welcome, connect your wallet:</h1>
            <button onClick={handleLogin}>Connect</button>
          </div>
        )}
      </header>
      {connectedAccount && (
        <main>
          <div>
            <label>Amount (ETH):</label>
            <input
              type="number"
              min="0.001"
              step="0.001"
              defaultValue={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <button disabled={amount < 0.001} onClick={pay}>
            PAY
          </button>
        </main>
      )}
    </div>
  );
}
